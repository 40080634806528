import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    Heading,
    Text,
    Textarea,
    Spinner,
    Image,
    Center,
    IconButton
} from '@chakra-ui/react';
import { CheckIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick'; // Import Slider component from react-slick
import 'slick-carousel/slick/slick.css'; // Import slick-carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import slick-carousel theme styles
import axios from '../api';
import ReactGA from "react-ga4";

const ContainerDetailPage = () => {
    const { containerName } = useParams();
    const decodedContainerName = decodeURIComponent(containerName);
    const [loRAs, setLoRAs] = useState([]);
    const [selectedImages, setSelectedImages] = useState({});
    const [promptInput, setPromptInput] = useState('');
    const [outputText, setOutputText] = useState('');
    const [loading, setLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const outputRef = useRef(null);

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                aria-label="Next"
                icon={<ArrowForwardIcon color="rgba(255, 255, 255, 1)" />}
                onClick={onClick}
                position="absolute"
                right="0"
                top="50%"
                transform="translateY(-50%)"
                zIndex="1"
                bg="rgba(0, 0, 0, 0.5)"
                _hover={{ bg: 'rgba(0, 0, 0, 0.7)' }}
            />
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                aria-label="Previous"
                icon={<ArrowBackIcon color="rgba(255, 255, 255, 1)" />}
                onClick={onClick}
                position="absolute"
                left="0"
                top="50%"
                transform="translateY(-50%)"
                zIndex="1"
                bg="rgba(0, 0, 0, 0.5)"
                _hover={{ bg: 'rgba(0, 0, 0, 0.7)' }}
            />
        );
    };

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 3,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024, // At screen widths less than 1024px
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 600, // At screen widths less than 600px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
        ],
    };

    useEffect(() => {
        const fetchContainerData = async () => {
            try {
                setLoading(true);
                // Fetch the garden data
                const response = await axios.get('/garden');
                const garden = response.data;

                // Find the specified container
                const container = garden.containers.find(
                    (c) => c.name === decodedContainerName
                );

                if (!container) {
                    console.error('Container not found');
                    return;
                }

                // Fetch detailed data for each LoRA in the container
                const loRADataPromises = container.loRAs.map((loraId) =>
                    axios.get(`/models/${loraId}`)
                );
                const loRAResponses = await Promise.all(loRADataPromises);
                const loRAsData = loRAResponses.map((res) => res.data);

                setLoRAs(loRAsData);
            } catch (error) {
                console.error('Error fetching container data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchContainerData();
    }, [decodedContainerName]);

    useEffect(() => {
        if (outputText && outputRef.current) {
            outputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [outputText]);


    // Function to toggle image selection
    const handleImageToggle = (modelId, imageUrl) => {
        setSelectedImages((prevSelectedImages) => {
            const imagesForModel = { ...(prevSelectedImages[modelId] || {}) };
            if (imagesForModel[imageUrl]) {
                // Deselect the image
                delete imagesForModel[imageUrl];
            } else {
                // Select the image
                imagesForModel[imageUrl] = true;
            }
            return {
                ...prevSelectedImages,
                [modelId]: imagesForModel,
            };
        });
    };

    // Handler for generate button
    const handleGenerate = async () => {
        setIsGenerating(true); // Start the loading indicator
        // For each selected model
        const outputData = loRAs.map((model) => {
            const selectedModelImages = selectedImages[model.id] || {};
            const selectedImagePrompts = [];

            // For each image in the model
            model.images.forEach((image) => {
                if (selectedModelImages[image.url]) {
                    // Get the prompts from image.meta if available
                    if (image.meta && image.meta.prompt) {
                        selectedImagePrompts.push(image.meta.prompt);
                    }
                }
            });

            return {
                id: model.id,
                name: model.name,
                creatorName: model.creatorName,
                imageUrl: model.imageUrl,
                description: model.description,
                trainedWords: model.trainedWords,
                selectedImagePrompts: selectedImagePrompts,
            };
        });

        // Prepare the data to send to the backend
        const requestData = {
            modelsData: outputData,
            userPrompt: promptInput,
        };

        try {
            // Send a POST request to the backend
            const response = await axios.post('/generate', requestData);
            const generatedPrompt = response.data.generatedPrompt;

            ReactGA.event({
                category: "API",
                action: "Generated prompt",
                label: "Prompt"
            });

            // Update the outputText with the generated prompt
            setOutputText(generatedPrompt);
        } catch (error) {
            console.error('Error generating prompt:', error);
        } finally {
            setIsGenerating(false); // Stop the loading indicator
        }
    };

    return (
        <div>
            <Heading mb={6}>{decodedContainerName}</Heading>
            {loading ? (
                <Spinner size="xl" />
            ) : (
                <>
                    {/* Display carousels for each LoRA */}
                    {loRAs.map((model) => (
                        <Box key={model.id} mb={6}>
                            <Heading size="md" mb={4}>
                                {model.name}
                            </Heading>
                            {/* Carousel */}
                            {model.images && model.images.length > 0 ? (
                                <Slider {...sliderSettings}>
                                    {model.images.map((image) => {
                                        const isSelected =
                                            selectedImages[model.id] && selectedImages[model.id][image.url];
                                        return (
                                            <Box
                                                key={image.url}
                                                position="relative"
                                                p={2}
                                                onClick={() => handleImageToggle(model.id, image.url)}
                                                cursor="pointer"
                                            >
                                                <Box
                                                    width="200px"
                                                    height="200px"
                                                    overflow="hidden"
                                                    borderRadius="md"
                                                >
                                                    {/* Image */}
                                                    <Image
                                                        src={image.url}
                                                        alt={`${model.name}`}
                                                        objectFit="cover"
                                                        objectPosition="center"
                                                        width="100%"
                                                        height="100%"
                                                        borderRadius="md"
                                                    />
                                                </Box>

                                                {/* Overlay checkmark when selected */}
                                                {isSelected && (
                                                    <Center
                                                        position="absolute"
                                                        top="0"
                                                        left="0"
                                                        width="100%"
                                                        height="100%"
                                                        bg="rgba(0, 0, 0, 0.5)"
                                                        borderRadius="md"
                                                    >
                                                        <CheckIcon color="white" boxSize={10} />
                                                    </Center>
                                                )}

                                                {/* Overlay with prompt text on hover */}
                                                <Box
                                                    position="absolute"
                                                    top="0"
                                                    left="0"
                                                    width="100%"
                                                    height="100%"
                                                    bg="rgba(0, 0, 0, 0.7)"
                                                    opacity="0"
                                                    _hover={{ opacity: '1' }}
                                                    transition="opacity 0.3s ease-in-out"
                                                    borderRadius="md"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    padding="10px"
                                                    textAlign="center"
                                                >
                                                    <Text color="white" fontSize="sm">
                                                        {image.meta && image.meta.prompt
                                                            ? image.meta.prompt
                                                            : 'No prompt available'}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Slider>
                            ) : (
                                <Text>No images available for this LoRA.</Text>
                            )}
                        </Box>
                    ))}

                    {/* Prompt input */}
                    <Box mt={6}>
                        <Textarea
                            placeholder="Enter your prompt here..."
                            value={promptInput}
                            onChange={(e) => setPromptInput(e.target.value)}
                            mb={4}
                        />
                        <Button onClick={handleGenerate}>Generate</Button>
                        {isGenerating && <Spinner size="md" ml={4} />}
                    </Box>

                    {/* Output */}
                    {outputText && (
                        <Box mt={6} ref={outputRef}>
                            <Heading size="md" mb={2}>
                                Generated Prompt
                            </Heading>
                            <Textarea value={outputText} readOnly height="300px" />
                        </Box>
                    )}
                </>
            )}
        </div>
    );
};

export default ContainerDetailPage;